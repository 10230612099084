@import '../../styles/mixins';

.common-kupos-checkbox {
    display: inline;
    label{
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .kupos-checkbox{
        display: inline-block;
        // width: 18px;
        // height: 18px;
        // border: 1px solid var(--secondary-color);
        // overflow: hidden;
        margin-right: 10px;
        &.circle{
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }
    }
    input{
        display: none;
    }
    img{
        display: none;
    }
    input:checked + span {
        // background-color: var(--secondary-color);
        // display: block;
        // height: 50%;
        // width: 50%;
        // border-radius: 50%;
        // margin-top: 25%;
        // margin-left: 25%;
    }
    input:checked + img{
        display: block;
    }
    .checkbox-label{
        margin-top: 2px;
    }

}
