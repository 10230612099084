@import '../../styles/mixins';

.common-kupos-success-failure {

    .cancel-done-section{
        margin-top: 40px;
      }
      
      .cancel-done-section img{
        height: 90px;
        width: auto;
      }
      
      .cancel-ticket-done{
        display: flex;
        justify-content: center;
        margin-top: 12px;
      }
      
      .cancel-ticket-done:nth-child(3),
      .cancel-ticket-done:nth-child(4){
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      
      .cancel-done-section .cancel-success-info-div{
        background: var(--secondary-color);
        border-radius: 10px;
        color: var(--white-color);
        margin: 12px auto;
        margin-top: 20px;
        padding: 6px 25px;
        border-radius: 5px;
        width: auto;
      }
      
      .primary-triangle{
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 19px solid var(--secondary-color);
        top: -15px;
        left: 20%;
        position: absolute;
        z-index: -1;
      }
      
      .bank-deposit-cards{
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        grid-gap: 25px;
        margin-top: 30px;
      }
    
    .successful-purchase-buttons{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .successful-purchase-buttons > button{
        margin: 0 10px;
        // margin-top: 70px;
        height: 40px;
        width: 250px;
    }
    .success-failure-content{
      margin-top: 45px;
    }

    .download-our-app-section-container{
      margin-bottom: 250px;
      @media only screen and (min-width: 1400px) {
        margin-bottom: 325px;
      }
    }

    .download-our-app-section {
        position: relative;
        // background-image: url(/images/download-success-page-banner-2.png);
        // background-size: auto 100%;
        margin: 0 -15px;
        // background-repeat: no-repeat;
        // background-position: bottom;
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
        img.banner-back {
          position: absolute;
          width: 104.4%;
          right: -26px;
        }
        .download-app-overlay-text-items {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 118px;
            left: 0;
            top: 0;
            top: 85px;
            width: 50%;
            @media only screen  and (min-width: 1400px){
              &{
                top: 100px;
              }
            }
            @media only screen  and (min-width: 1600px){
              &{
                top: 115px;
              }
            }
            .download-app-overlay-text-details {
              margin: 10px 0 5px;
            }
            .download-app-kupos-pay-image {
              margin: 7px;
            }
            & > *{
                display: block;
                text-align: center;
                // width: 65%;
            }       
            .download-app-kupos-pay-image img {
                width: 175px;
                height: auto;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .download-app-kupos-pay-store img {
                height: auto;
                width: 130px;                
                margin-right: 30px;
                margin-top: 5px;
            }   
        }
        img.app-screenshot {
            height: 145%;
            position: absolute;
            top: -15%;
            left: 0;
            width: auto;
        }
    }

}
