@import '../../styles/mixins';

.common-svg-amenities {
    .ac-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .ac-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    
    .restrooms-white-cls-1,.restrooms-white-cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}
    .restrooms-grey-cls-1,.restrooms-grey-cls-2{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;}
    .restrooms-white-cls-1,.restrooms-grey-cls-1{stroke-width:30px;}.restrooms-white-cls-2,.restrooms-grey-cls-2{stroke-width:26px;}

    .charger-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .charger-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}

    .food-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .food-white-cls-2{fill:#fff;}
    .food-grey-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .food-grey-cls-2{fill:#fff;}

    .mobile-ticket-white-cls-1,.mobile-ticket-white-cls-3{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}
    .mobile-ticket-white-cls-2{fill:#fff;}
    .mobile-ticket-grey-cls-1,.mobile-ticket-grey-cls-3{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;}
    .mobile-ticket-grey-cls-2{fill:#464648;}
    .mobile-ticket-white-cls-1,.mobile-ticket-grey-cls-1{stroke-width:30px;}
    .mobile-ticket-white-cls-3,.mobile-ticket-grey-cls-3{stroke-width:20px;}

    .music-white-cls-1,.music-white-cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}
    .music-grey-cls-1,.music-grey-cls-2{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;}
    .music-white-cls-1,.music-grey-cls-1{stroke-width:30px;}.music-white-cls-2,.music-grey-cls-2{stroke-width:46px;}

    .snack-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .snack-white-cls-2{fill:#fff;}
    .snack-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .snack-grey-cls-2{fill:#464648;}

    .baggage-white-cls-1,.baggage-white-cls-2{fill:none;stroke:#fff;}
    .baggage-grey-cls-1,.baggage-grey-cls-2{fill:none;stroke:#464648;}
    .baggage-white-cls-1,.baggage-grey-cls-1{stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .baggage-white-cls-2,.baggage-grey-cls-2{stroke-linecap:square;stroke-miterlimit:10;stroke-width:46px;}

    .tv-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .tv-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}

    .handicap-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .handicap-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}

    .wifi-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .wifi-white-cls-2{fill:#fff;}
    .wifi-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .wifi-grey-cls-2{fill:#464648;}

    .water-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .water-white-cls-2{fill:#fff;}
    .water-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .water-grey-cls-2{fill:#464648;}

    .game-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .game-white-cls-2{fill:#fff;}
    .game-grey-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:30px;}
    .game-grey-cls-2{fill:#464648;}
}

