@import '../../styles/mixins';

.common-kupos-button {
}

.button-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
