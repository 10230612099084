@import '../../styles/mixins';

.common-kupos-map {
    .gmnoprint {
        display: none;
    }
    .gm-fullscreen-control{
        display: none;
    }
    .autocomplete-places.kupos-border {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 15px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        img{
            position: absolute;
            height: 50%;
            width: auto;
            filter: brightness(0.5);
            left: 10px;
        }
        input {
            border: 0;
            height: 100%;
            width: 100%;
            padding: 10px;
            padding-left: 40px;
            padding-top: 12px;
            font-family: 'AvenirNextLTPro-Regular' , sans-serif !important;
        }
    }
    .info-window {
        width: 220px;
        padding: 15px;
        font-family: 'AvenirNextLTPro-Regular' , sans-serif !important;
        .kupos-button{
            height: 35px;
            width: 100%;
            margin-top: 6px;
        }
        .marker-icon-name {
            margin-bottom: 7px;
            display: flex;
            align-items: center;
            span.marker-icon {
                height: 20px;
                width: 20px;
                display: inline-flex !important;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                margin-right: 7px;
                img{
                    width: 160%;
                    position: relative;
                    top: 16%;
                }
            }
        }
        .marker-service {
            line-height: 1.4;
            span{
                display: inline;
            }
        }
        hr {
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }
    .gm-style .gm-style-iw-d{
        max-height: initial !important;
        overflow: hidden !important;
        max-width: 220px !important;
    }
    .gm-style-iw{
        padding: 0px;
        max-height: initial  !important;
        margin-left: -55px;
        & > button{
            display: none !important;
        }
    }
}
