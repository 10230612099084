@import '../../styles/mixins';

.common-kupos-time-picker {
  position: relative;
  width: 100%;
  // margin-top: 15px;
  & > div {
    position: relative;
  }
  input {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    padding-top: 12px;
    height: 40px;
    border: 1px solid var(--border-color);
    // font-size: 1em;
    padding-left: 45px;
    // margin-top: 15px;
    color: var(--primary-text-color);
    width: 100%;
  }
  .hPEdlB {
    font-family: 'AvenirNextLTPro-Regular', sans-serif;
  }
  .date-inner {
    height: 40px;
  }
  &.error input {
    border-color: red;
  }
  img {
    z-index: 9;
    position: absolute;
    left: 12px;
    top: 50%;
    width: 22px;
    margin-top: -9px;
    height: auto;
  }
  span.svg-span {
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 12px;
  }
  span.error {
    color: red;
    font-size: 0.6em;
    margin-top: 5px;
    display: block;
  }
  & > .time-picker {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-width: 200px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px 18px;
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.08);
  }
  .time-picker-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    /* justify-content: center; */
    text-align: center;
    position: relative;
  }
  .time-counter {
    display: flex;
    flex-direction: column;
  }
  .arrow {
    position: relative;
    text-align: center;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    margin: 3px 0px;
    border-radius: 5px;
    cursor: pointer;
  }
  .up.arrow {
    // transform: rotate(180deg);
  }
  .arrow img {
    width: 15px;
    height: 15px;
    position: static;
    margin: 0;
    padding: 0;
  }
  .value {
    padding: 12px 0px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 40px;
    input {
      width: 100%;
      height: 100%;
      border: 0;
      min-width: initial;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }
  .ampm .arrow img {
    opacity: 0;
  }
  .ampm .value {
    padding-top: 13px;
  }
}
