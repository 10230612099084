@import '../../styles/mixins';

.home-counter-dashboard {
  .counter-dashboard-container .step-1,
  .counter-dashboard-container .step-2 {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    // gap: 20px;
  }

  .counter-dashboard-container .step-1 .quick-reports,
  .counter-dashboard-container .step-1 .booking-summary,
  .counter-dashboard-container .step-2 .quick-links,
  .counter-dashboard-container .step-2 .counter-summary {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .counter-dashboard-container .step-1 .booking-summary {
    // max-height: 40vh;
  }
}
