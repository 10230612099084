@import '../../styles/mixins';

.common-kupos-modal-with-buttons {
    .kupos-modal-title2{
        margin-top: 15px;
    }
    .kupos-modal-bodyTex {
        text-align: center;
        margin-top: 15px;
    }
}
