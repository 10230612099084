@import '../../styles/mixins';

.common-kupos-counter {
    margin-top: 3px;
    width: 100%;
    max-width: 200px;
    .coumter-inner {
        height: 40px;
        align-items: center;
        border-radius: 10px;
        img {
            padding: 0;
            margin: 0 6px;
        }
    }
    span.svg-span {
        width: 20px;
        margin: 10px;
    }
}
