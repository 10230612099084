@import '../../styles/mixins';

.common-kupos-data-not-found {
  margin-top: 80px;
  .icon {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .icon img {
    width: 80px;
  }
  .title,
  .sub-title {
    margin-top: 10px;
  }
  .info-text-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-text {
    background: var(--primary-color);
    border-radius: 10px;
    color: var(--white-color);
    margin: 12px auto;
    margin-top: 15px;
    padding: 6px 25px;
    border-radius: 5px;
    width: auto;
    position: relative;
  }
  .info-text:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 13px solid var(--primary-color);
    top: -12px;
    left: 20%;
    position: absolute;
  }
}
