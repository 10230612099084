@import '../../styles/mixins';

.common-kupos-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    label{
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .kupos-radio{
        display: inline-block;
        // width: 20px;
        // height: 20px;
        // border-radius: 10px;
        // border: 1px solid var(--secondary-color);
        // overflow: hidden;
        margin-right: 10px;
    }
    input{
        display: none;
    }
    input:checked + span {
        // background-color: var(--secondary-color);
        // display: block;
        // height: 50%;
        // width: 50%;
        // border-radius: 50%;
        // margin-top: 25%;
        // margin-left: 25%;
    }
}
