@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@-webkit-keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }
@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009; }
  .pro-sidebar > .pro-sidebar-inner {
    background: #1d1d1d;
    height: 100%;
    position: relative;
    z-index: 101; }
    .pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      opacity: 0.3;
      left: 0;
      top: 0;
      z-index: 100; }
    .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 101; }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
        border-bottom: 1px solid rgba(173, 173, 173, 0.2); }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
        flex-grow: 1; }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
        border-top: 1px solid rgba(173, 173, 173, 0.2); }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
        list-style-type: none;
        padding: 0;
        margin: 0; }
  .pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: none; }
  .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px; }
  .pro-sidebar.rtl {
    text-align: right;
    direction: rtl; }
  @media (max-width: 480px) {
    .pro-sidebar.xs {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xs.collapsed {
        left: -80px; }
      .pro-sidebar.xs.toggled {
        left: 0; }
        .pro-sidebar.xs.toggled .overlay {
          display: block; }
      .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xs.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xs.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 576px) {
    .pro-sidebar.sm {
      position: fixed;
      left: -270px; }
      .pro-sidebar.sm.collapsed {
        left: -80px; }
      .pro-sidebar.sm.toggled {
        left: 0; }
        .pro-sidebar.sm.toggled .overlay {
          display: block; }
      .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.sm.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.sm.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 768px) {
    .pro-sidebar.md {
      position: fixed;
      left: -270px; }
      .pro-sidebar.md.collapsed {
        left: -80px; }
      .pro-sidebar.md.toggled {
        left: 0; }
        .pro-sidebar.md.toggled .overlay {
          display: block; }
      .pro-sidebar.md.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.md.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.md.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 992px) {
    .pro-sidebar.lg {
      position: fixed;
      left: -270px; }
      .pro-sidebar.lg.collapsed {
        left: -80px; }
      .pro-sidebar.lg.toggled {
        left: 0; }
        .pro-sidebar.lg.toggled .overlay {
          display: block; }
      .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.lg.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.lg.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 1200px) {
    .pro-sidebar.xl {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xl.collapsed {
        left: -80px; }
      .pro-sidebar.xl.toggled {
        left: 0; }
        .pro-sidebar.xl.toggled .overlay {
          display: block; }
      .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xl.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xl.rtl.toggled {
          left: auto;
          right: 0; } }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: '';
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px; }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05); }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
      padding-top: 15px;
      padding-bottom: 15px; }
  .pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #adadad; }
    .pro-sidebar .pro-menu a:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent; }
    .pro-sidebar .pro-menu a:hover {
      color: #d8d8d8; }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px; }
    .pro-sidebar .pro-menu .pro-menu-item.active {
      color: #d8d8d8; }
    .pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
      display: flex;
      margin-right: 5px;
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 35px 8px 20px;
      cursor: pointer; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
        outline: none;
        color: #d8d8d8; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
        margin-right: 10px;
        font-size: 14px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        flex-grow: 1;
        flex-shrink: 1; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
        color: #d8d8d8; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
          -webkit-animation: swing ease-in-out 0.5s 1 alternate;
                  animation: swing ease-in-out 0.5s 1 alternate; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
      background: #adadad; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
      position: absolute;
      right: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        display: inline-block;
        border-style: solid;
        border-color: #adadad;
        border-width: 0 2px 2px 0;
        padding: 2.5px;
        vertical-align: middle;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
      background: transparent !important; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
      padding-left: 20px; }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
        padding: 8px 30px 8px 15px; }
        .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
          display: none; }
        .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
          display: none; }
  .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: rgba(255, 255, 255, 0.05); }
  .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 0; }
  .pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 4px; }
  .pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 50%; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
    opacity: 0; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
    background-color: rgba(29, 29, 29, 0.95);
    z-index: 111; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
    position: relative; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
      pointer-events: none; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
        display: none; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
      height: auto !important;
      position: fixed;
      visibility: hidden;
      min-width: 220px;
      max-width: 270px;
      background-color: transparent;
      max-height: 100%;
      padding-left: 3px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
        padding-left: 10px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
        max-height: 100vh;
        overflow-y: auto;
        background-color: rgba(29, 29, 29, 0.95);
        padding-left: 20px;
        border-radius: 4px; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
      transition: visibility, -webkit-transform 0.3s;
      transition: visibility, transform 0.3s;
      transition: visibility, transform 0.3s, -webkit-transform 0.3s;
      visibility: visible; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
      -webkit-animation: swing ease-in-out 0.5s 1 alternate;
              animation: swing ease-in-out 0.5s 1 alternate; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
      padding: 8px 30px 8px 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 20px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px; }
    .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
      margin-right: 0;
      margin-left: 15px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
    padding-right: 10px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    padding-left: 0;
    padding-right: 20px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px; }

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; }

.popper-element[data-popper-placement^='left'] > .popper-arrow {
  right: 0;
  border-right: 7px solid rgba(29, 29, 29, 0.95); }

.popper-element[data-popper-placement^='right'] > .popper-arrow {
  left: 0;
  border-left: 7px solid rgba(29, 29, 29, 0.95); }

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; }

.react-slidedown.transitioning {
  overflow-y: hidden; }

.react-slidedown.closed {
  display: none; }

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: 'Roboto', sans-serif; }

#root {
  height: 100%; }

.pro-sidebar {
  height: 100vh; }

.app {
  height: 100%;
  display: flex;
  position: relative; }
  .app .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none; }
  .app .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .app .sidebar-btn-wrapper .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden; }
      .app .sidebar-btn-wrapper .sidebar-btn span {
        margin-left: 5px;
        font-size: 13px; }
      .app .sidebar-btn-wrapper .sidebar-btn:hover {
        color: #d8d8d8; }
  .app .collapsed .sidebar-btn {
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 40px;
    padding: 0; }
  .app main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }
    .app main header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef; }
      .app main header h1 {
        display: flex;
        align-items: center;
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px); }
    .app main footer {
      margin-top: auto;
      color: #888;
      text-align: center; }
      .app main footer a {
        text-decoration: none;
        color: #888; }
        .app main footer a:hover {
          text-decoration: underline; }
  .app .social-bagdes {
    margin-top: 10px; }
    .app .social-bagdes img {
      margin: 5px; }
  .app .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454; }
    .app .block > span {
      margin-left: 10px; }
  .app.rtl {
    direction: rtl;
    text-align: right; }
    .app.rtl header h1 {
      -webkit-transform: translateX(20px);
              transform: translateX(20px); }
    .app.rtl .block {
      direction: rtl; }
      .app.rtl .block > span {
        margin-left: 0;
        margin-right: 10px; }
    .app.rtl .sidebar-btn-wrapper .sidebar-btn span {
      margin-left: 0;
      margin-right: 5px; }
  @media (max-width: 767.98px) {
    .app {
      overflow-x: hidden; }
      .app header h1 {
        font-size: 24px; } }
  @media (max-width: 768px) {
    .app .btn-toggle {
      display: flex; }
    .app.rtl .btn-toggle {
      margin-left: auto; } }

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px; }
  .badge.red {
    color: #ffffff;
    background: #d63030; }
  .badge.gray {
    color: #ffffff;
    background: #777777; }
  .badge.yellow {
    color: #000000;
    background: #ffd331; }

.center {
  text-align: 'center';
}

.ticket_table {
  width: 90%;
}

.ticket_table tr td {
  font-family: sans-serif;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.home-welcome-page {
  text-align: center; }
  .home-welcome-page .app {
    text-align: center; }
  .home-welcome-page .rekit-logo {
    -webkit-animation: app-logo-spin infinite 10s linear;
            animation: app-logo-spin infinite 10s linear;
    height: 80px; }
  .home-welcome-page .app-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
    overflow: hidden;
    position: relative; }
  .home-welcome-page .app-title {
    font-size: 1.5em; }
  .home-welcome-page .app-intro {
    font-size: large; }
  .home-welcome-page ul,
  .home-welcome-page li {
    list-style: none;
    margin: 0;
    padding: 0; }
  .home-welcome-page ul {
    margin-top: 20px; }
  .home-welcome-page li {
    margin-top: 10px; }
  .home-welcome-page a {
    color: #0288d1;
    text-decoration: none; }
    .home-welcome-page a:hover {
      text-decoration: underline; }
  .home-welcome-page p.memo {
    width: 500px;
    color: #999;
    font-size: 12px;
    line-height: 150%;
    margin: auto; }

@-webkit-keyframes app-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes app-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.home-add-location-area .polygon-input {
  font-size: 15px;
  padding: 15px; }

.home-admin-home {
  display: flex;
  justify-content: space-evenly; }

.home-choose-shift-and-counter {
  height: 80vh;
  width: 60vw;
  margin: auto; }
  .home-choose-shift-and-counter .shift-main-container {
    justify-content: center;
    height: 100%;
    padding-top: 20vh; }
    .home-choose-shift-and-counter .shift-main-container .dropdown-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 20px; }
    .home-choose-shift-and-counter .shift-main-container .button-container {
      display: flex;
      justify-content: center; }
  .home-choose-shift-and-counter .header-conatiner {
    border-bottom: solid 1px #bbb;
    margin-bottom: 20px;
    padding-bottom: 10px; }

.home-cash-in-hand {
  height: 80vh;
  width: 60vw;
  margin: auto; }
  .home-cash-in-hand .shift-main-container {
    justify-content: center;
    height: 100%;
    padding-top: 20vh; }
    .home-cash-in-hand .shift-main-container .dropdown-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 20px; }
    .home-cash-in-hand .shift-main-container .button-container {
      display: flex;
      justify-content: center; }
  .home-cash-in-hand .header-conatiner {
    border-bottom: solid 1px #bbb;
    margin-bottom: 20px;
    padding-bottom: 10px; }

.home-counter-dashboard .counter-dashboard-container .step-1,
.home-counter-dashboard .counter-dashboard-container .step-2 {
  display: grid;
  grid-template-columns: 1fr 1.5fr; }

.home-counter-dashboard .counter-dashboard-container .step-1 .quick-reports,
.home-counter-dashboard .counter-dashboard-container .step-1 .booking-summary,
.home-counter-dashboard .counter-dashboard-container .step-2 .quick-links,
.home-counter-dashboard .counter-dashboard-container .step-2 .counter-summary {
  border: 1px solid #ccc;
  padding: 10px; }

.common-kupos-input {
  width: 100%; }
  .common-kupos-input .input-outer {
    border-radius: 10px;
    align-items: center;
    margin-top: 3px;
    overflow: hidden;
    box-shadow: none; }
    .common-kupos-input .input-outer.error {
      border-color: red; }
  .common-kupos-input input {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    padding: 10px;
    padding-top: 12px;
    min-width: 160px;
    border: 0;
    flex: 1 1;
    box-shadow: none;
    color: var(--primary-text-color); }
  .common-kupos-input .country-code-container {
    border-right: 1px solid #ccc; }
    .common-kupos-input .country-code-container input {
      width: 100%;
      min-width: initial; }
  .common-kupos-input span.error {
    color: red;
    font-size: 0.6em;
    margin-top: 5px;
    display: block; }
  .common-kupos-input .icon-right {
    margin-right: 10px; }

.common-kupos-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px; }
  .common-kupos-radio label {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .common-kupos-radio .kupos-radio {
    display: inline-block;
    margin-right: 10px; }
  .common-kupos-radio input {
    display: none; }

.common-kupos-checkbox {
  display: inline; }
  .common-kupos-checkbox label {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .common-kupos-checkbox .kupos-checkbox {
    display: inline-block;
    margin-right: 10px; }
    .common-kupos-checkbox .kupos-checkbox.circle {
      width: 20px;
      height: 20px;
      border-radius: 10px; }
  .common-kupos-checkbox input {
    display: none; }
  .common-kupos-checkbox img {
    display: none; }
  .common-kupos-checkbox input:checked + img {
    display: block; }
  .common-kupos-checkbox .checkbox-label {
    margin-top: 2px; }

.common-kupos-success-failure .cancel-done-section {
  margin-top: 40px; }

.common-kupos-success-failure .cancel-done-section img {
  height: 90px;
  width: auto; }

.common-kupos-success-failure .cancel-ticket-done {
  display: flex;
  justify-content: center;
  margin-top: 12px; }

.common-kupos-success-failure .cancel-ticket-done:nth-child(3),
.common-kupos-success-failure .cancel-ticket-done:nth-child(4) {
  display: flex;
  justify-content: center;
  margin-top: 20px; }

.common-kupos-success-failure .cancel-done-section .cancel-success-info-div {
  background: var(--secondary-color);
  border-radius: 10px;
  color: var(--white-color);
  margin: 12px auto;
  margin-top: 20px;
  padding: 6px 25px;
  border-radius: 5px;
  width: auto; }

.common-kupos-success-failure .primary-triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 19px solid var(--secondary-color);
  top: -15px;
  left: 20%;
  position: absolute;
  z-index: -1; }

.common-kupos-success-failure .bank-deposit-cards {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 25px;
  margin-top: 30px; }

.common-kupos-success-failure .successful-purchase-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

.common-kupos-success-failure .successful-purchase-buttons > button {
  margin: 0 10px;
  height: 40px;
  width: 250px; }

.common-kupos-success-failure .success-failure-content {
  margin-top: 45px; }

.common-kupos-success-failure .download-our-app-section-container {
  margin-bottom: 250px; }
  @media only screen and (min-width: 1400px) {
    .common-kupos-success-failure .download-our-app-section-container {
      margin-bottom: 325px; } }

.common-kupos-success-failure .download-our-app-section {
  position: relative;
  margin: 0 -15px;
  margin-top: 60px;
  display: flex;
  justify-content: flex-end; }
  .common-kupos-success-failure .download-our-app-section img.banner-back {
    position: absolute;
    width: 104.4%;
    right: -26px; }
  .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 118px;
    left: 0;
    top: 0;
    top: 85px;
    width: 50%; }
    @media only screen and (min-width: 1400px) {
      .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items {
        top: 100px; } }
    @media only screen and (min-width: 1600px) {
      .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items {
        top: 115px; } }
    .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items .download-app-overlay-text-details {
      margin: 10px 0 5px; }
    .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items .download-app-kupos-pay-image {
      margin: 7px; }
    .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items > * {
      display: block;
      text-align: center; }
    .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items .download-app-kupos-pay-image img {
      width: 175px;
      height: auto;
      margin-top: 5px;
      margin-bottom: 5px; }
    .common-kupos-success-failure .download-our-app-section .download-app-overlay-text-items .download-app-kupos-pay-store img {
      height: auto;
      width: 130px;
      margin-right: 30px;
      margin-top: 5px; }
  .common-kupos-success-failure .download-our-app-section img.app-screenshot {
    height: 145%;
    position: absolute;
    top: -15%;
    left: 0;
    width: auto; }

.common-kupos-modal-with-buttons .kupos-modal-title2 {
  margin-top: 15px; }

.common-kupos-modal-with-buttons .kupos-modal-bodyTex {
  text-align: center;
  margin-top: 15px; }

.common-kupos-month-picker .react-month-picker .calendar-container {
  left: 0;
  border-radius: 10px;
  border: 0;
  top: 8px;
  box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.08); }

.common-kupos-month-picker .month-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
  color: #fff;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 12px; }

.common-kupos-month-picker .month-label .svg-span {
  width: 15px;
  position: static;
  margin: 0;
  cursor: pointer; }

.common-kupos-month-picker .month-label .monyear {
  margin-top: 2px; }

.common-kupos-month-picker .month-calendar {
  position: absolute;
  width: 260px;
  top: 17px;
  border-radius: 10px;
  color: #464647; }

.common-kupos-month-picker .col_mp {
  display: block;
  float: left;
  text-align: center;
  cursor: pointer; }

.common-kupos-month-picker .span_1_of_3_mp {
  width: calc(33.33% - 24px);
  margin: 2px 12px; }

.common-kupos-month-picker .col_mp {
  padding-bottom: 7px;
  padding-top: 9px;
  border-radius: 5px;
  text-transform: uppercase; }

.common-kupos-month-picker .calendar-container.readonly .col_mp:hover {
  background-color: transparent;
  cursor: default;
  color: inherit; }

.common-kupos-month-picker .col_mp:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
  font-style: normal; }

.common-kupos-month-picker .selected_date_mp {
  font-size: 12px;
  color: darkslateblue;
  font-weight: bold; }

.common-kupos-month-picker .selected_cell {
  background-color: var(--light-primary-color);
  color: var(--primary-color);
  border-radius: 5px;
  font-style: normal; }

.month-calendar-capsule {
  background: var(--secondary-color);
  border-radius: 75px;
  /* height: 30px; */
  display: flex;
  width: 220px;
  align-items: center;
  color: var(--white-color);
  /* margin-top: 30px; */
  position: relative;
  cursor: pointer; }
  .month-calendar-capsule .click-container {
    width: 100%;
    height: 100%;
    padding: 5px 30px;
    padding-top: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.button-loader-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.common-kupos-calendar {
  width: 260px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.08); }
  .common-kupos-calendar b {
    font-weight: normal !important; }
  .common-kupos-calendar .month-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    color: #fff;
    margin-bottom: 8px;
    border-radius: 10px;
    padding: 10px; }
    .common-kupos-calendar .month-label img {
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      width: 22px;
      height: auto;
      position: static;
      margin: 0; }
    .common-kupos-calendar .month-label .svg-span {
      width: 15px;
      position: static;
      margin: 0;
      cursor: pointer; }
  .common-kupos-calendar .WeekDays {
    display: flex;
    text-align: center; }
    .common-kupos-calendar .WeekDays .unit {
      background-color: transparent;
      flex: 1 1;
      color: var(--primary-color);
      padding: 5px 0; }
  .common-kupos-calendar .monyear {
    margin-top: 2px; }
  .common-kupos-calendar .monyear.select-month-year b {
    position: relative; }
  .common-kupos-calendar .monyear.select-month-year b:nth-child(1) {
    margin-right: 20px; }
  .common-kupos-calendar .monyear.select-month-year b img {
    margin-left: 5px;
    margin-top: -3px; }
  .common-kupos-calendar .days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center; }
  .common-kupos-calendar .day-number {
    width: 28px;
    height: 28px;
    padding: 2px; }
    .common-kupos-calendar .day-number .day-number-inner {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      padding-top: 5px; }
    .common-kupos-calendar .day-number.selected .day-number-inner {
      background-color: var(--light-primary-color);
      color: var(--primary-color); }
    .common-kupos-calendar .day-number.past .day-number-inner,
    .common-kupos-calendar .day-number.deactivate .day-number-inner {
      opacity: 0.3; }
    .common-kupos-calendar .day-number.next-month {
      height: 1px; }
  .common-kupos-calendar .month-picker .react-month-picker .calendar-container {
    left: 0;
    border-radius: 10px;
    border: 0;
    top: 8px;
    box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.08); }
  .common-kupos-calendar .month-picker .month-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    color: #fff;
    margin-bottom: 8px;
    border-radius: 10px;
    padding: 12px; }
  .common-kupos-calendar .month-picker .month-label .svg-span {
    width: 15px;
    position: static;
    margin: 0;
    cursor: pointer; }
  .common-kupos-calendar .month-picker .month-label .monyear {
    margin-top: 2px; }
  .common-kupos-calendar .month-picker .month-calendar {
    position: absolute;
    width: 260px;
    top: 0px;
    left: 0;
    border-radius: 10px;
    color: #464647; }
  .common-kupos-calendar .month-picker .col_mp {
    display: block;
    float: left;
    text-align: center;
    cursor: pointer; }
  .common-kupos-calendar .month-picker .span_1_of_3_mp {
    width: calc(33.33% - 24px);
    margin: 2px 12px; }
  .common-kupos-calendar .month-picker .col_mp {
    padding-bottom: 7px;
    padding-top: 9px;
    border-radius: 5px;
    text-transform: uppercase; }
  .common-kupos-calendar .month-picker .calendar-container.readonly .col_mp:hover {
    background-color: transparent;
    cursor: default;
    color: inherit; }
  .common-kupos-calendar .month-picker .col_mp:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    font-style: normal; }
  .common-kupos-calendar .month-picker .selected_date_mp {
    font-size: 12px;
    color: darkslateblue;
    font-weight: bold; }
  .common-kupos-calendar .month-picker .selected_cell {
    background-color: var(--light-primary-color);
    color: var(--primary-color);
    border-radius: 5px;
    font-style: normal; }
  .common-kupos-calendar .month-calendar-capsule {
    background: var(--secondary-color);
    border-radius: 75px;
    /* height: 30px; */
    display: flex;
    width: 220px;
    align-items: center;
    color: var(--white-color);
    /* margin-top: 30px; */
    position: relative;
    cursor: pointer; }
    .common-kupos-calendar .month-calendar-capsule .click-container {
      width: 100%;
      height: 100%;
      padding: 8px 30px;
      padding-top: 9px;
      display: flex;
      align-items: center; }

.common-svg-home .arrow-right-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 40px; }

.common-svg-home .calendar-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-home .calendar-cls-2 {
  fill: #464648; }

.common-svg-home .cancel-ticket-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-home .cancel-ticket-cls-2 {
  fill: #fff; }

.common-svg-home .print-ticket-cls-1 {
  fill: #fff; }

.common-svg-home .print-ticket-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-home .close-cls-1 {
  fill: #464648; }

.common-svg-home .close-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 28px; }

.common-svg-home .facebook-cls-1 {
  fill: #3b5998; }

.common-svg-home .facebook-cls-2 {
  fill: #fff; }

.common-svg-home .googleplus-cls-1 {
  fill: #464647; }

.common-svg-home .googleplus-cls-2 {
  fill: #fff; }

.common-svg-home .back-arrow-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-home .time-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 26px; }

.common-svg-home .time-cls-2 {
  fill: #464648; }

.common-svg-home .minus-cls-1 {
  fill: #464648; }

.common-svg-home .minus-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-home .plus-cls-1 {
  fill: #464648; }

.common-svg-home .plus-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 20px; }

.common-svg-amenities .ac-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .ac-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .restrooms-white-cls-1, .common-svg-amenities .restrooms-white-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .restrooms-grey-cls-1, .common-svg-amenities .restrooms-grey-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .restrooms-white-cls-1, .common-svg-amenities .restrooms-grey-cls-1 {
  stroke-width: 30px; }

.common-svg-amenities .restrooms-white-cls-2, .common-svg-amenities .restrooms-grey-cls-2 {
  stroke-width: 26px; }

.common-svg-amenities .charger-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .charger-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .food-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .food-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .food-grey-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .food-grey-cls-2 {
  fill: #fff; }

.common-svg-amenities .mobile-ticket-white-cls-1, .common-svg-amenities .mobile-ticket-white-cls-3 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .mobile-ticket-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .mobile-ticket-grey-cls-1, .common-svg-amenities .mobile-ticket-grey-cls-3 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .mobile-ticket-grey-cls-2 {
  fill: #464648; }

.common-svg-amenities .mobile-ticket-white-cls-1, .common-svg-amenities .mobile-ticket-grey-cls-1 {
  stroke-width: 30px; }

.common-svg-amenities .mobile-ticket-white-cls-3, .common-svg-amenities .mobile-ticket-grey-cls-3 {
  stroke-width: 20px; }

.common-svg-amenities .music-white-cls-1, .common-svg-amenities .music-white-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .music-grey-cls-1, .common-svg-amenities .music-grey-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round; }

.common-svg-amenities .music-white-cls-1, .common-svg-amenities .music-grey-cls-1 {
  stroke-width: 30px; }

.common-svg-amenities .music-white-cls-2, .common-svg-amenities .music-grey-cls-2 {
  stroke-width: 46px; }

.common-svg-amenities .snack-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .snack-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .snack-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .snack-grey-cls-2 {
  fill: #464648; }

.common-svg-amenities .baggage-white-cls-1, .common-svg-amenities .baggage-white-cls-2 {
  fill: none;
  stroke: #fff; }

.common-svg-amenities .baggage-grey-cls-1, .common-svg-amenities .baggage-grey-cls-2 {
  fill: none;
  stroke: #464648; }

.common-svg-amenities .baggage-white-cls-1, .common-svg-amenities .baggage-grey-cls-1 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .baggage-white-cls-2, .common-svg-amenities .baggage-grey-cls-2 {
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 46px; }

.common-svg-amenities .tv-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .tv-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .handicap-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .handicap-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .wifi-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .wifi-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .wifi-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .wifi-grey-cls-2 {
  fill: #464648; }

.common-svg-amenities .water-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .water-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .water-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .water-grey-cls-2 {
  fill: #464648; }

.common-svg-amenities .game-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .game-white-cls-2 {
  fill: #fff; }

.common-svg-amenities .game-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px; }

.common-svg-amenities .game-grey-cls-2 {
  fill: #464648; }

.common-svg-service-list .duration-arrow-cls-1 {
  fill: none;
  stroke: #464647;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px; }

.common-svg-circular-icons .user-orange-cls-1 {
  fill: #f15a24;
  opacity: 0.14; }

.common-svg-circular-icons .user-orange-cls-2, .common-svg-circular-icons .user-orange-cls-3, .common-svg-circular-icons .user-orange-cls-4 {
  opacity: 0.67; }

.common-svg-circular-icons .user-orange-cls-2 {
  fill: url(#radial-gradient); }

.common-svg-circular-icons .user-orange-cls-3 {
  fill: url(#radial-gradient-2); }

.common-svg-circular-icons .user-orange-cls-4 {
  fill: url(#radial-gradient-3); }

.common-svg-circular-icons .user-orange-cls-5 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .user-orange-cls-6 {
  fill: #fff; }

.common-svg-circular-icons .forgot-password-cls-1 {
  isolation: isolate; }

.common-svg-circular-icons .forgot-password-cls-2, .common-svg-circular-icons .forgot-password-cls-7 {
  fill: #f15a24; }

.common-svg-circular-icons .forgot-password-cls-2 {
  opacity: 0.14; }

.common-svg-circular-icons .forgot-password-cls-3 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .forgot-password-cls-13, .common-svg-circular-icons .forgot-password-cls-4, .common-svg-circular-icons .forgot-password-cls-5 {
  fill: #fff; }

.common-svg-circular-icons .forgot-password-cls-4 {
  opacity: 0.3;
  mix-blend-mode: soft-light; }

.common-svg-circular-icons .forgot-password-cls-6 {
  fill: url(#radial-gradient); }

.common-svg-circular-icons .forgot-password-cls-7 {
  opacity: 0.42; }

.common-svg-circular-icons .forgot-password-cls-8 {
  fill: url(#radial-gradient-2); }

.common-svg-circular-icons .forgot-password-cls-9 {
  fill: #ffb654; }

.common-svg-circular-icons .forgot-password-cls-10, .common-svg-circular-icons .forgot-password-cls-11, .common-svg-circular-icons .forgot-password-cls-12 {
  opacity: 0.67; }

.common-svg-circular-icons .forgot-password-cls-10 {
  fill: url(#radial-gradient-3); }

.common-svg-circular-icons .forgot-password-cls-11 {
  fill: url(#radial-gradient-4); }

.common-svg-circular-icons .forgot-password-cls-12 {
  fill: url(#radial-gradient-5); }

.common-svg-circular-icons .forgot-password-cls-13 {
  font-size: 40.57px;
  font-family: Roboto-Black, Roboto; }

.common-svg-circular-icons .pasajebus-orange-cls-1 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .pasajebus-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .pasajebus-cls-2 {
  fill: #f97e48;
  opacity: 0.47; }

.common-svg-circular-icons .pasajebus-cls-3 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .pasajebus-cls-4 {
  fill: #fff; }

.common-svg-circular-icons .pasajebus-cls-5 {
  fill: url(#linear-gradient-2); }

.common-svg-circular-icons .pasajebus-cls-6 {
  fill: #ffb654; }

.common-svg-circular-icons .pasajebus-cls-7 {
  fill: none; }

.common-svg-circular-icons .pasajebus-cls-8 {
  fill: url(#linear-gradient-3); }

.common-svg-circular-icons .pasajebus-cls-9 {
  fill: url(#linear-gradient-4); }

.common-svg-circular-icons .pasajebus-cls-10 {
  fill: url(#linear-gradient-5); }

.common-svg-circular-icons .carpool-orange-cls-2 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .carpool-white-cls-2 {
  fill: #fff; }

.common-svg-circular-icons .carpool-cls-1 {
  fill: none; }

.common-svg-circular-icons .carpool-cls-3 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .carpool-cls-4 {
  fill: #fff; }

.common-svg-circular-icons .carpool-cls-5 {
  fill: #ff7b34; }

.common-svg-circular-icons .carpool-cls-6 {
  fill: #ffb654; }

.common-svg-circular-icons .carpool-cls-7, .common-svg-circular-icons .carpool-cls-9 {
  fill: #cd4927; }

.common-svg-circular-icons .carpool-cls-7, .common-svg-circular-icons .carpool-cls-8 {
  opacity: 0.3; }

.common-svg-circular-icons .carpool-cls-10 {
  -webkit-clip-path: url(#clip-path);
          clip-path: url(#clip-path); }

.common-svg-circular-icons .carpool-cls-11 {
  fill: #ffc263; }

.common-svg-circular-icons .carpool-cls-12 {
  fill: #f97e48;
  opacity: 0.47; }

.common-svg-circular-icons .transfer-orange-cls-4 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .transfer-white-cls-4 {
  fill: #fff; }

.common-svg-circular-icons .transfer-cls-1 {
  fill: none; }

.common-svg-circular-icons .transfer-cls-2 {
  opacity: 0.14; }

.common-svg-circular-icons .transfer-cls-5, .common-svg-circular-icons .transfer-cls-8, .common-svg-circular-icons .transfer-cls-9 {
  fill: #cd4927; }

.common-svg-circular-icons .transfer-cls-5 {
  opacity: 0.2; }

.common-svg-circular-icons .transfer-cls-6 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .transfer-cls-7 {
  fill: #ff7b34; }

.common-svg-circular-icons .transfer-cls-8 {
  opacity: 0.6; }

.common-svg-circular-icons .transfer-cls-15, .common-svg-circular-icons .transfer-cls-9 {
  opacity: 0.3; }

.common-svg-circular-icons .transfer-cls-10 {
  fill: #ffc369; }

.common-svg-circular-icons .transfer-cls-11 {
  fill: #fff; }

.common-svg-circular-icons .transfer-cls-12 {
  fill: #ffca6c; }

.common-svg-circular-icons .transfer-cls-13 {
  fill: url(#radial-gradient); }

.common-svg-circular-icons .transfer-cls-14 {
  fill: url(#linear-gradient-2); }

.common-svg-circular-icons .transfer-cls-15, .common-svg-circular-icons .transfer-cls-16 {
  fill: #ce471d; }

.common-svg-circular-icons .transfer-cls-16 {
  opacity: 0.44; }

.common-svg-circular-icons .train-orange-cls-1 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .train-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .train-cls-2 {
  fill: url(#radial-gradient); }

.common-svg-circular-icons .train-cls-10, .common-svg-circular-icons .train-cls-3, .common-svg-circular-icons .train-cls-6, .common-svg-circular-icons .train-cls-9 {
  fill: #ce471d; }

.common-svg-circular-icons .train-cls-3 {
  opacity: 0.4; }

.common-svg-circular-icons .train-cls-4 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .train-cls-10, .common-svg-circular-icons .train-cls-5 {
  opacity: 0.3; }

.common-svg-circular-icons .train-cls-7 {
  fill: #ff8f45; }

.common-svg-circular-icons .train-cls-8 {
  fill: #fff; }

.common-svg-circular-icons .train-cls-9 {
  opacity: 0.44; }

.common-svg-circular-icons .train-cls-11 {
  fill: #ffb654; }

.common-svg-circular-icons .collective-orange-cls-1 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .collective-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .collective-cls-5 {
  fill: #f15a24; }

.common-svg-circular-icons .collective-cls-2 {
  fill: #f97e48;
  opacity: 0.47; }

.common-svg-circular-icons .collective-cls-3 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .collective-cls-11, .common-svg-circular-icons .collective-cls-4 {
  fill: #fff; }

.common-svg-circular-icons .collective-cls-5 {
  opacity: 0.1; }

.common-svg-circular-icons .collective-cls-6 {
  fill: #ff8f45; }

.common-svg-circular-icons .collective-cls-7 {
  fill: #cd4927;
  opacity: 0.3; }

.common-svg-circular-icons .collective-cls-8 {
  fill: #ce471d;
  opacity: 0.6; }

.common-svg-circular-icons .collective-cls-9 {
  fill: #ffb654; }

.common-svg-circular-icons .collective-cls-10 {
  fill: #ff7b34; }

.common-svg-circular-icons .collective-cls-11 {
  font-size: 42px;
  font-family: AvenirNextLTPro-Bold, Avenir Next LT Pro;
  font-weight: 700;
  letter-spacing: -0.01em; }

.common-svg-circular-icons .collective-cls-12 {
  letter-spacing: 0.05em; }

.common-svg-circular-icons .toll-orange-cls-1 {
  fill: #f15a24;
  opacity: .14; }

.common-svg-circular-icons .toll-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .toll-cls-2 {
  fill: #ff8f45; }

.common-svg-circular-icons .toll-cls-3, .common-svg-circular-icons .toll-cls-6, .common-svg-circular-icons .toll-cls-8 {
  fill: #ce471d; }

.common-svg-circular-icons .toll-cls-3 {
  opacity: 0.4; }

.common-svg-circular-icons .toll-cls-4 {
  fill: #fff; }

.common-svg-circular-icons .toll-cls-5 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .toll-cls-6, .common-svg-circular-icons .toll-cls-7 {
  opacity: 0.3; }

.common-svg-circular-icons .toll-cls-9 {
  opacity: 0.44; }

.common-svg-circular-icons .toll-cls-10 {
  fill: #ecf0f1; }

.common-svg-circular-icons .bip-orange-cls-1 {
  fill: #f15a24;
  opacity: 0.14; }

.common-svg-circular-icons .bip-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .bip-cls-2 {
  fill: #f97e48;
  opacity: 0.47; }

.common-svg-circular-icons .bip-cls-3 {
  fill: #ff7600; }

.common-svg-circular-icons .bip-cls-4 {
  fill: url(#linear-gradient); }

.common-svg-circular-icons .bip-cls-5 {
  fill: #fff; }

.common-svg-circular-icons .go-orange-cls-1 {
  fill: #f15a24;
  opacity: 0.14; }

.common-svg-circular-icons .go-white-cls-1 {
  fill: #fff; }

.common-svg-circular-icons .go-cls-1 {
  fill: none; }

.common-svg-circular-icons .go-cls-2 {
  opacity: 0.14; }

.common-svg-circular-icons .go-cls-8 {
  fill: #f15a24; }

.common-svg-circular-icons .go-cls-3 {
  fill: #ff7b34; }

.common-svg-circular-icons .go-cls-10, .common-svg-circular-icons .go-cls-4, .common-svg-circular-icons .go-cls-5 {
  fill: #cd4927; }

.common-svg-circular-icons .go-cls-4, .common-svg-circular-icons .go-cls-8, .common-svg-circular-icons .go-cls-9 {
  opacity: 0.3; }

.common-svg-circular-icons .go-cls-5 {
  opacity: 0.4; }

.common-svg-circular-icons .go-cls-6 {
  fill: url(#radial-gradient); }

.common-svg-circular-icons .go-cls-7 {
  fill: #fff; }

.common-svg-circular-icons .go-cls-11 {
  -webkit-clip-path: url(#clip-path);
          clip-path: url(#clip-path); }

.common-kupos-map .gmnoprint {
  display: none; }

.common-kupos-map .gm-fullscreen-control {
  display: none; }

.common-kupos-map .autocomplete-places.kupos-border {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  overflow: hidden; }
  .common-kupos-map .autocomplete-places.kupos-border img {
    position: absolute;
    height: 50%;
    width: auto;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    left: 10px; }
  .common-kupos-map .autocomplete-places.kupos-border input {
    border: 0;
    height: 100%;
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    padding-top: 12px;
    font-family: 'AvenirNextLTPro-Regular' , sans-serif !important; }

.common-kupos-map .info-window {
  width: 220px;
  padding: 15px;
  font-family: 'AvenirNextLTPro-Regular' , sans-serif !important; }
  .common-kupos-map .info-window .kupos-button {
    height: 35px;
    width: 100%;
    margin-top: 6px; }
  .common-kupos-map .info-window .marker-icon-name {
    margin-bottom: 7px;
    display: flex;
    align-items: center; }
    .common-kupos-map .info-window .marker-icon-name span.marker-icon {
      height: 20px;
      width: 20px;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 7px; }
      .common-kupos-map .info-window .marker-icon-name span.marker-icon img {
        width: 160%;
        position: relative;
        top: 16%; }
  .common-kupos-map .info-window .marker-service {
    line-height: 1.4; }
    .common-kupos-map .info-window .marker-service span {
      display: inline; }
  .common-kupos-map .info-window hr {
    margin-top: 8px;
    margin-bottom: 8px; }

.common-kupos-map .gm-style .gm-style-iw-d {
  max-height: initial !important;
  overflow: hidden !important;
  max-width: 220px !important; }

.common-kupos-map .gm-style-iw {
  padding: 0px;
  max-height: initial  !important;
  margin-left: -55px; }
  .common-kupos-map .gm-style-iw > button {
    display: none !important; }

.common-kupos-counter {
  margin-top: 3px;
  width: 100%;
  max-width: 200px; }
  .common-kupos-counter .coumter-inner {
    height: 40px;
    align-items: center;
    border-radius: 10px; }
    .common-kupos-counter .coumter-inner img {
      padding: 0;
      margin: 0 6px; }
  .common-kupos-counter span.svg-span {
    width: 20px;
    margin: 10px; }

.common-kupos-time-picker {
  position: relative;
  width: 100%; }
  .common-kupos-time-picker > div {
    position: relative; }
  .common-kupos-time-picker input {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    padding-top: 12px;
    height: 40px;
    border: 1px solid var(--border-color);
    padding-left: 45px;
    color: var(--primary-text-color);
    width: 100%; }
  .common-kupos-time-picker .hPEdlB {
    font-family: 'AvenirNextLTPro-Regular', sans-serif; }
  .common-kupos-time-picker .date-inner {
    height: 40px; }
  .common-kupos-time-picker.error input {
    border-color: red; }
  .common-kupos-time-picker img {
    z-index: 9;
    position: absolute;
    left: 12px;
    top: 50%;
    width: 22px;
    margin-top: -9px;
    height: auto; }
  .common-kupos-time-picker span.svg-span {
    width: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 12px; }
  .common-kupos-time-picker span.error {
    color: red;
    font-size: 0.6em;
    margin-top: 5px;
    display: block; }
  .common-kupos-time-picker > .time-picker {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-width: 200px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px 18px;
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.08); }
  .common-kupos-time-picker .time-picker-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    /* justify-content: center; */
    text-align: center;
    position: relative; }
  .common-kupos-time-picker .time-counter {
    display: flex;
    flex-direction: column; }
  .common-kupos-time-picker .arrow {
    position: relative;
    text-align: center;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0px;
    margin: 3px 0px;
    border-radius: 5px;
    cursor: pointer; }
  .common-kupos-time-picker .arrow img {
    width: 15px;
    height: 15px;
    position: static;
    margin: 0;
    padding: 0; }
  .common-kupos-time-picker .value {
    padding: 12px 0px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 40px; }
    .common-kupos-time-picker .value input {
      width: 100%;
      height: 100%;
      border: 0;
      min-width: initial;
      padding: 0;
      margin: 0;
      text-align: center; }
  .common-kupos-time-picker .ampm .arrow img {
    opacity: 0; }
  .common-kupos-time-picker .ampm .value {
    padding-top: 13px; }

.common-payment-options .payment-methods {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; }

.common-payment-options .payment-methods div input {
  margin-right: 10px; }

.common-payment-options .payment-block-heading {
  margin-bottom: 5px; }

.common-payment-options .payment-block-promo {
  margin-bottom: 20px;
  align-items: flex-start;
  min-height: 50px; }
  .common-payment-options .payment-block-promo .common-kupos-checkbox {
    margin-top: 10px; }

.common-payment-options .promocode-box {
  flex: 1 1;
  margin-left: 26px;
  align-items: flex-start; }
  .common-payment-options .promocode-box input {
    margin-top: 0;
    padding-top: 13px; }
  .common-payment-options .promocode-box .common-kupos-input {
    width: 100%;
    margin-right: 10px; }
  .common-payment-options .promocode-box .kupos-button {
    height: 40px;
    width: 150px;
    padding-top: 8px;
    border: 1px solid #fff; }

.common-payment-options .payment-methods img {
  height: auto;
  width: 110px;
  display: inline-flex;
  margin-right: 10px;
  align-items: center; }

.common-payment-options .payment-methods > div {
  flex: 2 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
  position: relative; }

.common-payment-options .payment-methods .kupos-pay-method {
  flex: 4 1;
  display: flex;
  justify-content: space-between;
  margin-right: 0px; }

.common-payment-options .payment-checkbox {
  position: absolute;
  z-index: 999;
  width: 50px;
  height: 100%;
  left: 0; }

.common-payment-options .payment-methods > div.kupos-pay {
  flex: 2 1;
  color: var(--primary-color);
  font-size: 1.1em;
  font-weight: bold;
  margin-right: 0px;
  display: block; }
  .common-payment-options .payment-methods > div.kupos-pay .kupos-pay-check {
    margin-bottom: 3px;
    margin-top: 11pt;
    /* position: relative; */ }
  .common-payment-options .payment-methods > div.kupos-pay span.kupos-amount {
    margin-left: 28px; }

.common-payment-options .accept-tnc {
  margin-top: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.common-payment-options .accept-tnc a {
  margin-top: 4px; }

.common-payment-options .tnc-error {
  margin-top: 10px; }

.common-payment-options .pay-now-section {
  padding: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0 !important; }

.common-payment-options .pay-now-button {
  width: 180px;
  line-height: 1.5;
  height: 40px; }

.common-payment-options .booking-error {
  padding: 10px; }

.common-cancellation-modal .accept-tnc {
  display: flex; }
  .common-cancellation-modal .accept-tnc .common-kupos-checkbox {
    position: relative;
    top: 2px; }

.common-kupos-select-box {
  position: relative;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 7px; }
  .common-kupos-select-box .css-2b097c-container {
    height: 100%;
    font-size: .95em; }
  .common-kupos-select-box .css-yk16xz-control {
    height: 100%;
    margin-top: 7px;
    padding-left: 30px;
    border-radius: 10px; }
  .common-kupos-select-box .css-1uccc91-singleValue {
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%); }
  .common-kupos-select-box .css-1hwfws3 {
    height: 100%; }
  .common-kupos-select-box.no-padding-left .css-yk16xz-control {
    padding-left: 0px; }
  .common-kupos-select-box.no-padding-left .css-1pahdxg-control {
    padding-left: 0px; }
  .common-kupos-select-box.drop-arrow .css-yk16xz-control {
    padding-right: 40px; }
  .common-kupos-select-box .css-1hb7zxy-IndicatorsContainer {
    display: none; }
  .common-kupos-select-box img {
    z-index: 9;
    position: absolute; }
  .common-kupos-select-box .icon-left {
    height: 40%;
    width: auto;
    /* top: 50%; */
    margin-left: 10px;
    margin-right: 0;
    position: relative; }
  .common-kupos-select-box .icon-arrow {
    height: 9px;
    width: auto;
    top: 50%;
    right: 0;
    margin-top: 2px;
    -webkit-filter: invert(60%);
            filter: invert(60%);
    z-index: 1;
    position: static;
    margin-right: 10px; }
  .common-kupos-select-box .icon-arrow.small-arrow {
    height: 10px;
    margin-top: -4px; }
  .common-kupos-select-box img.icon-arrow.up-side-down {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  .common-kupos-select-box div.css-1pahdxg-control {
    height: 100%;
    margin-top: 10px;
    padding-left: 30px; }
  .common-kupos-select-box .css-1wy0on6 {
    display: none; }
  .common-kupos-select-box img.icon-right {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px; }
  .common-kupos-select-box .css-1pahdxg-control {
    border-color: var(--border-color);
    border-width: 1px;
    box-shadow: 0 0 0 0;
    border-radius: 10px; }

.common-kupos-select-box-outer {
  flex: 1 1;
  position: relative; }
  .common-kupos-select-box-outer .is-error .css-yk16xz-control {
    border-color: red; }
  .common-kupos-select-box-outer span.error {
    color: red;
    margin-top: 5PX;
    display: block; }
  .common-kupos-select-box-outer .auto-suggestions {
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    z-index: 990;
    background-color: #fff;
    border: 1px solid #ccc;
    min-width: 100%;
    border-radius: 0 0 10px 10px;
    padding-top: 10px;
    margin-top: -10px; }
    .common-kupos-select-box-outer .auto-suggestions.up {
      bottom: 100%; }
    .common-kupos-select-box-outer .auto-suggestions.error-shown {
      margin-top: -25px; }
  .common-kupos-select-box-outer .auto-suggestions a {
    padding: 10px 14px;
    border-bottom: 1px solid #ccc;
    display: block;
    cursor: pointer; }
    .common-kupos-select-box-outer .auto-suggestions a.active {
      color: var(--primary-color);
      background-color: var(--light-primary-color);
      border-color: var(--primary-color); }
    .common-kupos-select-box-outer .auto-suggestions a.active:last-child, .common-kupos-select-box-outer .auto-suggestions a:last-child:hover {
      border-bottom: 0; }
    .common-kupos-select-box-outer .auto-suggestions a.cursor {
      color: var(--primary-color);
      background-color: var(--light-primary-color);
      border-color: var(--primary-color); }
    .common-kupos-select-box-outer .auto-suggestions a:hover {
      color: #fff;
      background-color: var(--secondary-color); }
  .common-kupos-select-box-outer input {
    height: 100%;
    flex: 1 1;
    width: 100%;
    border: 0;
    padding: 10px;
    padding-top: 13px;
    color: var(--primary-text-color);
    z-index: 2;
    background-color: transparent;
    cursor: pointer; }
  .common-kupos-select-box-outer input[readonly] {
    cursor: not-allowed; }

.common-dropdown-component {
  height: 40px;
  margin-top: 3px; }

.common-material-input-field {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px; }

.common-kupos-data-not-found {
  margin-top: 80px; }
  .common-kupos-data-not-found .icon {
    padding: 10px;
    display: flex;
    justify-content: center; }
  .common-kupos-data-not-found .icon img {
    width: 80px; }
  .common-kupos-data-not-found .title,
  .common-kupos-data-not-found .sub-title {
    margin-top: 10px; }
  .common-kupos-data-not-found .info-text-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .common-kupos-data-not-found .info-text {
    background: var(--primary-color);
    border-radius: 10px;
    color: var(--white-color);
    margin: 12px auto;
    margin-top: 15px;
    padding: 6px 25px;
    border-radius: 5px;
    width: auto;
    position: relative; }
  .common-kupos-data-not-found .info-text:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 13px solid var(--primary-color);
    top: -12px;
    left: 20%;
    position: absolute; }

.examples-side-panel {
  position: fixed;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 40px;
  width: 260px;
  height: 100%;
  background-color: #f7f7f7; }
  .examples-side-panel ul,
  .examples-side-panel li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .examples-side-panel li {
    padding: 8px; }
  .examples-side-panel a {
    color: #2db7f5;
    text-decoration: none; }
    .examples-side-panel a:hover {
      color: #f90; }
  .examples-side-panel .memo {
    font-size: 13px;
    margin-top: 40px;
    line-height: 150%;
    color: #aaa; }
    .examples-side-panel .memo:before {
      content: ' ';
      display: block;
      height: 2px;
      width: 60px;
      margin-bottom: 10px;
      background-color: #ddd; }

.examples-welcome-page {
  line-height: 160%;
  position: relative;
  color: #555; }
  .examples-welcome-page a {
    color: #2db7f5;
    text-decoration: none; }
    .examples-welcome-page a:hover {
      color: #f90; }
  .examples-welcome-page .app-logo {
    position: absolute;
    top: -14px;
    left: 0px;
    width: 50px; }
  .examples-welcome-page h1 {
    padding-left: 60px;
    margin-bottom: 40px;
    font-size: 28px; }
  .examples-welcome-page h3 {
    margin-top: 20px; }
  .examples-welcome-page code {
    font-size: 14px; }

.examples-counter-page {
  color: #555; }
  .examples-counter-page h1 {
    margin-top: 0px;
    font-size: 28px; }
  .examples-counter-page span {
    padding: 0 10px;
    display: inline-block;
    min-width: 30px;
    text-align: center; }
  .examples-counter-page button.btn-reset {
    margin-left: 15px; }

.examples-reddit-list-page {
  color: #555; }
  .examples-reddit-list-page h1 {
    margin-top: 0px;
    font-size: 28px; }
  .examples-reddit-list-page a {
    color: #2db7f5;
    text-decoration: none; }
    .examples-reddit-list-page a:hover {
      color: #f90; }
  .examples-reddit-list-page .fetch-list-error {
    display: block;
    margin-top: 20px;
    color: red; }
  .examples-reddit-list-page .no-items-tip {
    margin-top: 15px; }

.examples-layout .examples-page-container {
  padding: 40px 30px 0 300px;
  min-width: 400px;
  max-width: 700px; }

