@import '../../styles/mixins';

.common-kupos-input {
  width: 100%;
  .input-outer {
    border-radius: 10px;
    // justify-content: center;
    align-items: center;
    margin-top: 3px;
    overflow: hidden;
    box-shadow: none;

    &.error {
      border-color: red;
    }
  }
  input {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    // height: 50px;
    // font-size: .9em;
    padding: 10px;
    padding-top: 12px;
    min-width: 160px;
    border: 0;
    flex: 1;
    box-shadow: none;
    color: var(--primary-text-color);
  }
  .country-code-container {
    border-right: 1px solid #ccc;
    input {
      width: 100%;
      min-width: initial;
    }
  }
  span.error {
    color: red;
    font-size: 0.6em;
    margin-top: 5px;
    display: block;
  }
  .icon-right {
    margin-right: 10px;
  }
}
