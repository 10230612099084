@import '../../styles/mixins';

.common-kupos-select-box {
    position: relative;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    margin-top: 7px;
    // z-index: 991;
    .css-2b097c-container{
        height: 100%;
        font-size: .95em;
    }
    .css-yk16xz-control{
        height: 100%;
        margin-top: 7px;
        padding-left: 30px;
        border-radius: 10px;
    }
    .css-1uccc91-singleValue{
        transform: translateY(-30%);
    }
    .css-1hwfws3{
        height: 100%;
    }
    &.no-padding-left{
        .css-yk16xz-control{
            padding-left: 0px;
        }
        .css-1pahdxg-control{
            padding-left: 0px;
        }
    }
    &.drop-arrow{
        .css-yk16xz-control{
            padding-right: 40px;
        }
    }
    .css-1hb7zxy-IndicatorsContainer{
        display: none;
    }
    img{
        z-index: 9;
        position: absolute;
    }
    .icon-left{
        height: 40%;
        width: auto;
        /* top: 50%; */
        margin-left: 10px;
        margin-right: 0;
        position: relative;
    }
    
    .icon-arrow{  
        height: 9px;
        width: auto;
        top: 50%;
        right: 0;
        margin-top: 2px;
        filter: invert(60%);
        z-index: 1;
        position: static;
        margin-right: 10px;
    }
    .icon-arrow.small-arrow {
        height: 10px;
        margin-top: -4px;
    }
    img.icon-arrow.up-side-down {
        transform: rotateX(180deg);
    }
    div.css-1pahdxg-control{
        height: 100%;
        margin-top: 10px;
        padding-left: 30px;
    }
    .css-1wy0on6{
        display: none;
    }
    img.icon-right {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -10px;
    }
    .css-1pahdxg-control{
        border-color: var(--border-color);
        border-width: 1px;
        box-shadow: 0 0 0 0;
        border-radius: 10px;
    }
}

.common-kupos-select-box-outer{
    flex: 1;
    position: relative;
    .is-error{
        .css-yk16xz-control{
            border-color: red;
        }
    }
    span.error {
        color: red;
        margin-top:5PX;
        display: block;
    }
    .auto-suggestions {
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        z-index: 990;
        background-color: #fff;
        border: 1px solid #ccc;
        min-width: 100%;
        border-radius: 0 0 10px 10px;
        padding-top: 10px;
        margin-top: -10px;
        &.up{
            bottom: 100%;
        }
        &.error-shown{
            margin-top: -25px;
        }
    }
    .auto-suggestions a {
        padding: 10px 14px;
        border-bottom: 1px solid #ccc;
        display: block;
        cursor: pointer;
        &.active{
            color: var(--primary-color);
            background-color: var(--light-primary-color);
            border-color: var(--primary-color);
        }
        &.active:last-child,&:last-child:hover{
            border-bottom: 0;
        }
        &.cursor{
            color: var(--primary-color);
            background-color: var(--light-primary-color);
            border-color: var(--primary-color);
        }
        &:hover{
            color:#fff;
            background-color: var(--secondary-color);
        }
    }
    input{
        height: 100%;
        flex: 1;
        width: 100%;
        border: 0;
        padding: 10px;   
        padding-top: 13px;
        // padding-top: 15px;
        color: var(--primary-text-color);
        z-index: 2;
        background-color: transparent;
        cursor: pointer;
    }
    input[readonly] {
        cursor: not-allowed;
    }
}