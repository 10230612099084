@import '../../styles/mixins';

.home-choose-shift-and-counter {
  height: 80vh;
  width: 60vw;
  //   background-color: #f00;
  margin: auto;
  .shift-main-container {
    // display: flex;
    justify-content: center;
    height: 100%;
    padding-top: 20vh;

    .dropdown-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-top: 20px;
    }

    .button-container {
      display: flex;
      justify-content: center;
    }
  }
  .header-conatiner {
    border-bottom: solid 1px #bbb;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}
