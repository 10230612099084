.center {
  text-align: 'center';
}

.ticket_table {
  width: 90%;
}

.ticket_table tr td {
  font-family: sans-serif;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 16px;
}
