@import '../../styles/mixins';

.common-cancellation-modal {
    .accept-tnc{
        display: flex;
        .common-kupos-checkbox{
            position: relative;
            top: 2px;
        }
    }
}
