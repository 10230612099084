@import '../../styles/mixins';

.common-svg-home {
    .arrow-right-white-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:40px;}

    .calendar-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}
    .calendar-cls-2{fill:#464648;}


    .cancel-ticket-cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}.cancel-ticket-cls-2{fill:#fff;}
    .print-ticket-cls-1{fill:#fff;}.print-ticket-cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}
    .close-cls-1{fill:#464648;}.close-cls-2{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:28px;}

    .facebook-cls-1{fill:#3b5998;}
    .facebook-cls-2{fill:#fff;}
    .googleplus-cls-1{fill:#464647;}.googleplus-cls-2{fill:#fff;}
    .back-arrow-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}
    .time-cls-1{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:26px;}
    .time-cls-2{fill:#464648;}

    .minus-cls-1{fill:#464648;}.minus-cls-2{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}
    .plus-cls-1{fill:#464648;}.plus-cls-2{fill:none;stroke:#464648;stroke-linecap:round;stroke-linejoin:round;stroke-width:20px;}
}
