@import '../../styles/mixins';

.common-svg-circular-icons {
    .user-orange-cls-1{fill:#f15a24;opacity:0.14;}.user-orange-cls-2,.user-orange-cls-3,.user-orange-cls-4{opacity:0.67;}.user-orange-cls-2{fill:url(#radial-gradient);}.user-orange-cls-3{fill:url(#radial-gradient-2);}.user-orange-cls-4{fill:url(#radial-gradient-3);}.user-orange-cls-5{fill:url(#linear-gradient);}.user-orange-cls-6{fill:#fff;}
    .forgot-password-cls-1{isolation:isolate;}.forgot-password-cls-2,.forgot-password-cls-7{fill:#f15a24;}.forgot-password-cls-2{opacity:0.14;}.forgot-password-cls-3{fill:url(#linear-gradient);}.forgot-password-cls-13,.forgot-password-cls-4,.forgot-password-cls-5{fill:#fff;}.forgot-password-cls-4{opacity:0.3;mix-blend-mode:soft-light;}.forgot-password-cls-6{fill:url(#radial-gradient);}.forgot-password-cls-7{opacity:0.42;}.forgot-password-cls-8{fill:url(#radial-gradient-2);}.forgot-password-cls-9{fill:#ffb654;}.forgot-password-cls-10,.forgot-password-cls-11,.forgot-password-cls-12{opacity:0.67;}.forgot-password-cls-10{fill:url(#radial-gradient-3);}.forgot-password-cls-11{fill:url(#radial-gradient-4);}.forgot-password-cls-12{fill:url(#radial-gradient-5);}.forgot-password-cls-13{font-size:40.57px;font-family:Roboto-Black, Roboto;}
    .pasajebus-orange-cls-1{fill:#f15a24;opacity: .14;}.pasajebus-white-cls-1{fill:#fff;}
    .pasajebus-cls-2{fill:#f97e48;opacity:0.47;}.pasajebus-cls-3{fill:url(#linear-gradient);}.pasajebus-cls-4{fill:#fff;}.pasajebus-cls-5{fill:url(#linear-gradient-2);}.pasajebus-cls-6{fill:#ffb654;}.pasajebus-cls-7{fill:none;}.pasajebus-cls-8{fill:url(#linear-gradient-3);}.pasajebus-cls-9{fill:url(#linear-gradient-4);}.pasajebus-cls-10{fill:url(#linear-gradient-5);}
    .carpool-orange-cls-2{fill:#f15a24;opacity: .14;}.carpool-white-cls-2{fill:#fff;}
    .carpool-cls-1{fill:none;}.carpool-cls-3{fill:url(#linear-gradient);}.carpool-cls-4{fill:#fff;}.carpool-cls-5{fill:#ff7b34;}.carpool-cls-6{fill:#ffb654;}.carpool-cls-7,.carpool-cls-9{fill:#cd4927;}.carpool-cls-7,.carpool-cls-8{opacity:0.3;}.carpool-cls-10{clip-path:url(#clip-path);}.carpool-cls-11{fill:#ffc263;}.carpool-cls-12{fill:#f97e48;opacity:0.47;}
    .transfer-orange-cls-4{fill:#f15a24;opacity: .14;}.transfer-white-cls-4{fill:#fff;}
    .transfer-cls-1{fill:none;}.transfer-cls-2{opacity:0.14;}.transfer-cls-3{}.transfer-cls-5,.transfer-cls-8,.transfer-cls-9{fill:#cd4927;}.transfer-cls-5{opacity:0.2;}.transfer-cls-6{fill:url(#linear-gradient);}.transfer-cls-7{fill:#ff7b34;}.transfer-cls-8{opacity:0.6;}.transfer-cls-15,.transfer-cls-9{opacity:0.3;}.transfer-cls-10{fill:#ffc369;}.transfer-cls-11{fill:#fff;}.transfer-cls-12{fill:#ffca6c;}.transfer-cls-13{fill:url(#radial-gradient);}.transfer-cls-14{fill:url(#linear-gradient-2);}.transfer-cls-15,.transfer-cls-16{fill:#ce471d;}.transfer-cls-16{opacity:0.44;}
    .train-orange-cls-1{fill:#f15a24;opacity: .14;}.train-white-cls-1{fill:#fff;}
    .train-cls-2{fill:url(#radial-gradient);}.train-cls-10,.train-cls-3,.train-cls-6,.train-cls-9{fill:#ce471d;}.train-cls-3{opacity:0.4;}.train-cls-4{fill:url(#linear-gradient);}.train-cls-10,.train-cls-5{opacity:0.3;}.train-cls-7{fill:#ff8f45;}.train-cls-8{fill:#fff;}.train-cls-9{opacity:0.44;}.train-cls-11{fill:#ffb654;}
    .collective-orange-cls-1{fill:#f15a24;opacity: .14;}.collective-white-cls-1{fill:#fff;}
    .collective-cls-5{fill:#f15a24;}.collective-cls-2{fill:#f97e48;opacity:0.47;}.collective-cls-3{fill:url(#linear-gradient);}.collective-cls-11,.collective-cls-4{fill:#fff;}.collective-cls-5{opacity:0.1;}.collective-cls-6{fill:#ff8f45;}.collective-cls-7{fill:#cd4927;opacity:0.3;}.collective-cls-8{fill:#ce471d;opacity:0.6;}.collective-cls-9{fill:#ffb654;}.collective-cls-10{fill:#ff7b34;}.collective-cls-11{font-size:42px;font-family:AvenirNextLTPro-Bold, Avenir Next LT Pro;font-weight:700;letter-spacing:-0.01em;}.collective-cls-12{letter-spacing:0.05em;}
    .toll-orange-cls-1{fill:#f15a24;opacity: .14;}.toll-white-cls-1{fill:#fff;}
    .toll-cls-2{fill:#ff8f45;}.toll-cls-3,.toll-cls-6,.toll-cls-8{fill:#ce471d;}.toll-cls-3{opacity:0.4;}.toll-cls-4{fill:#fff;}.toll-cls-5{fill:url(#linear-gradient);}.toll-cls-6,.toll-cls-7{opacity:0.3;}.toll-cls-9{opacity:0.44;}.toll-cls-10{fill:#ecf0f1;}
    .bip-orange-cls-1{fill:#f15a24;opacity:0.14;}.bip-white-cls-1{fill:#fff;}
    .bip-cls-2{fill:#f97e48;opacity:0.47;}.bip-cls-3{fill:#ff7600;}.bip-cls-4{fill:url(#linear-gradient);}.bip-cls-5{fill:#fff;}
    .go-orange-cls-1{fill:#f15a24;opacity:0.14;}.go-white-cls-1{fill:#fff;}
    .go-cls-1{fill:none;}.go-cls-2{opacity:0.14;}.go-cls-8{fill:#f15a24;}.go-cls-3{fill:#ff7b34;}.go-cls-10,.go-cls-4,.go-cls-5{fill:#cd4927;}.go-cls-4,.go-cls-8,.go-cls-9{opacity:0.3;}.go-cls-5{opacity:0.4;}.go-cls-6{fill:url(#radial-gradient);}.go-cls-7{fill:#fff;}.go-cls-11{clip-path:url(#clip-path);}
}
